const requestStates = {
  // feasibility request states start here
  saved: "Saved",
  submitted: "Submitted",
  refine: "Refine",
  created: "Created",
  assigned: "Assigned",
  ongoing: "Ongoing",
  responded: "Responded",
  reported: "Reported",
  hold: "Hold",
  // all of the above + below: access request states
  evaluation: "Evaluation",
  decided: "Decided",
  signed: "Signed",
  transfer: "Transfer",
  completed: "Completed",
  returned: "Returned"
};

export default requestStates;
